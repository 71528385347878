import * as _isGraph2 from "graphology-utils/is-graph";

var _isGraph = "default" in _isGraph2 ? _isGraph2.default : _isGraph2;

import * as _defaults2 from "graphology-utils/defaults";

var _defaults = "default" in _defaults2 ? _defaults2.default : _defaults2;

import _iterate from "./iterate.js";
import _helpers from "./helpers.js";
import _defaults3 from "./defaults.js";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/**
 * Graphology Force Layout Worker
 * ===============================
 *
 * A worker made for running a force layout live.
 *
 * Note that it does not run in a webworker yet but respect animation frames.
 */
const isGraph = _isGraph;
const resolveDefaults = _defaults;
const iterate = _iterate;
const helpers = _helpers;
const DEFAULTS = _defaults3;

function ForceSupervisor(graph, params) {
  // Validation
  if (!isGraph(graph)) throw new Error("graphology-layout-force/worker: the given graph is not a valid graphology instance.");
  params = resolveDefaults(params, DEFAULTS);
  (this || _global).callbacks = {};
  if (params.onConverged) (this || _global).callbacks.onConverged = params.onConverged;
  (this || _global).graph = graph;
  (this || _global).params = params;
  (this || _global).nodeStates = {};
  (this || _global).frameID = null;
  (this || _global).running = false;
  (this || _global).killed = false; // TODO: hook listeners on graph to listen to dropNode, dropEdge, clear, clearEdges
}

ForceSupervisor.prototype.isRunning = function () {
  return (this || _global).running;
};

ForceSupervisor.prototype.runFrame = function () {
  let {
    converged
  } = iterate((this || _global).graph, (this || _global).nodeStates, (this || _global).params);
  helpers.assignLayoutChanges((this || _global).graph, (this || _global).nodeStates, (this || _global).params); // TODO: figure out convergence

  converged = false;

  if (converged) {
    if ((this || _global).callbacks.onConverged) (this || _global).callbacks.onConverged();
    this.stop();
  } else {
    (this || _global).frameID = window.requestAnimationFrame(() => this.runFrame());
  }
};

ForceSupervisor.prototype.stop = function () {
  (this || _global).running = false;

  if ((this || _global).frameID !== null) {
    window.cancelAnimationFrame((this || _global).frameID);
    (this || _global).frameID = null;
  }

  return this || _global;
};

ForceSupervisor.prototype.start = function () {
  if ((this || _global).killed) throw new Error("graphology-layout-force/worker.start: layout was killed.");
  if ((this || _global).running) return;
  (this || _global).running = true;
  this.runFrame();
};

ForceSupervisor.prototype.kill = function () {
  this.stop();
  delete (this || _global).nodeStates;
  (this || _global).killed = true; // TODO: cleanup events
};

exports = ForceSupervisor;
export default exports;